<template>

  <div class="content">
    <div class="title">
      短信传情
    </div>
    <div class="main">
      <div class="card"
           style="background: rgb(255, 255, 255); border-radius: 15px; margin: 12px 0px; padding: 26px 32px;">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <span class="card-text1">你瞧</span>
          <span class="card-text" style="margin-left: 14px;">被人记起的感觉多么美好</span>
        </div>
        <div class="line-15"></div>
        <div class="card-text">至少在这个世界上</div>
        <div class="card-text">你能真真切切的想到我</div>
        <van-button color="#00D477" to="/write" class="card-btn" round type="primary">去发短信</van-button>

        <div class="card-img"></div>
      </div>

      <div class="combo-content">


      </div>


      <!--      <ComboRechargeItem-->
      <!--          avgPrice="1.0"-->
      <!--          num="15"-->
      <!--          tip="主流套餐"-->
      <!--          note="推荐！83.7%用户选择"-->
      <!--          price="1"/>-->
      <van-swipe v-if="bannerList != null" class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item @click="clickBanner(index)" v-for="(banner, index) in bannerList">
          <img :src="banner.bannerUrl" class="subscription-img">
        </van-swipe-item>
      </van-swipe>

    </div>
  </div>
</template>

<script>
import {Button, Swipe, SwipeItem,ImagePreview } from 'vant';
import ComboRechargeItem from "../../components/ComboRechargeItem";
import {getJsAPiSign, getUserInfo, getHomeBanner} from "../../api/user";
import store from "../../store";

import wx from "weixin-js-sdk";

export default {
  name: "index",
  data() {
    return {
      clientHeight: '',
      bannerList: null,
      showBanner: false,
    }
  },
  created() {
    // console.log(this.$route.query.channel)
    if (this.$route.query.channel)
      store.commit('setChannel', this.$route.query.channel)
    getUserInfo()

    getHomeBanner().then(res => {
      console.log(res.data.data)
      if (res.data.code === '00000'){
        this.bannerList = res.data.data
      }
    })
    // let url = {'url': window.location.href}
    // getJsAPiSign(url)
    //     .then(res => {
    //       wx.config({
    //         debug: false, // 开启调试模式,
    //         appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
    //         timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
    //         nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
    //         signature: res.data.data.signature,// 必填，签名，见附录1
    //         jsApiList: [], // 必填，需要使用的JS接口列表，所有JS接口列
    //         openTagList: ['wx-open-launch-weapp']
    //       });
    //     })
  },
  methods: {
    toSubscription() {
      window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg3NDc1MzU5OA==#wechat_redirect'
    },
    clickBanner(e) {
      let banner = this.bannerList[e]
      switch (banner.jumpType){
        case 0:
          break;
        case 1:
          ImagePreview([
            banner.paramsData
          ]);
          break;
        case 2:
          window.location.href = banner.paramsData
          break;
        case 3:
          break;
        default:
          break;
      }
      console.log(e)
    }
  },
  components: {
    [Button.name]: Button,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [ImagePreview.Component.name]: ImagePreview.Component,
    ComboRechargeItem,
  },


}
</script>

<style scoped>
.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  background: #f7f7f7;
  /*padding: 0 15px ;*/
  padding: 0 15px 65px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}


.title {
  font-size: 22px;
  color: #292833;
  font-weight: bold;
  height: 40px;
  padding: 15px;
  line-height: 40px;
  z-index: 999;
  background: #f7f7f7;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.line-15 {
  height: 15px;
}


.card-text {
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  white-space: pre-wrap;
}

.card-text1 {
  box-sizing: border-box;
  display: block;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  border-radius: 2px;
  color: #292833;
  background: #f1f1f1;
  width: 40px;
}

.card-btn {
  width: 140px;
  height: 40px;
  margin-top: 30px;
}

.card-img {
  height: 130px;
  background: url('../../assets/image/01.png') right center / contain no-repeat;
  margin-top: -80px;
}

.card {
  align-content: flex-start;
  border: 0 solid #000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-width: 0;
  background: rgb(255, 255, 255);
  border-radius: 15px;
  margin: 12px 0px;
  padding: 26px 32px;
}

.combo-content {
  flex: 1;
}
.my-swipe{
  box-sizing: border-box;
  width: 345px;
}

.subscription-img {
  width: 345px;
  height: 100px;
  float: bottom;
}
</style>